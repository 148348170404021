<script setup>
import MainHeaders from './components/MainHeaders.vue'
import LoginUser from "@/components/LoginUser.vue";
import {ref} from 'vue'

const token = ref(localStorage.getItem("access"));
</script>



<template>
  <v-app>
    <template v-if="token">
      <MainHeaders/>
      <v-main class="mx-8 mt-5 pb-11 pr-4" style="background: #F3F9FC;">
        <router-view :key="$route.fullPath"></router-view>
      </v-main>
    </template>
    <template v-else>
      <login-user></login-user>
    </template>
  </v-app>
</template>


<style>
@import "assets/style.scss";

#app {
  font-family: 'Montserrat', sans-serif;
}
</style>
